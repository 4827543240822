// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useCallback, useEffect, useRef, memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {    
// } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- QUILL ------
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIChipBox,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Memomize ---
const TextFieldMemo = memo(TextField)
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const quillModules = {
    toolbar: [          
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
    ],
}
// ----------------------------------------------------------------------
    
export default memo((props) => {  
    const [formData, setFormData] = useState(props.settings)
    const isMounted = useRef(false)    

    useEffect(() => {
        if (isMounted.current) {
            console.log("11")
                if (props.onChange)
            props.onChange({...formData})
        } else {            
            isMounted.current = true
        }    
    }, [formData])
    
    const handleOnChange = useCallback((event) => {
        const id = (event.target.name || event.target.id) 
        const value = event.target.value

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}
            return newState
        })
        
        return true
    }, [])

    return (
        <>          
            <FormControl sx={{mb: 2}}
                fullWidth 
                required                                            
            >
                <InputLabel>API adgang</InputLabel>
                <Select                                                
                    name="apiCredentialId"
                    label="API adgang"
                    value={formData.apiCredentialId}
                    onChange={handleOnChange}
                >
                    {props.apiCredentials.map(credential => {
                        if (credential.type == "smtp2go") {
                            return (
                                <MenuItem value={credential.id}>{credential.title}</MenuItem>
                            )
                        }
                    })}
                </Select>
            </FormControl>                                                   
            <TextFieldMemo sx={{mb: 2}}
                name="sender"
                label="Fra"                                                
                fullWidth
                value={formData.sender}
                onChange={handleOnChange}
            />                              
            <SimpleUIChipBox sx={{mb: 2}}
                name="to" 
                label={"Til"}                                                                                 
                variant="outlined"
                freeSolo
                disableBackspaceRemoveOption                            
                value={formData.to}
                onChange={handleOnChange}
            />    
            <TextFieldMemo sx={{mb: 2}}
                name="subject"
                label="Emne"                                                
                fullWidth
                value={formData.subject}
                onChange={handleOnChange}
            />    
            <ReactQuill
                theme="snow" 
                modules={quillModules} 
                value={formData.bodyHTML}                                                
                onChange={(value) => handleOnChange({target: {name: "bodyHTML", value: value}})}
                handleOnChange
            />
        </>
    )
})