// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,    
    Stack,
    DialogContentText,
    IconButton,
    Collapse,
    Alert,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {    
    Refresh as RefreshIcon,    
    Edit as EditIcon,    
    Delete as DeleteIcon,
    Add as AddIcon,        
    Close as CloseIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {isAdministrator, API_URL_PHONEZOLUTIONS} from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIListview,
    SimpleUIDrawer,
    SimpleUIDialogConfirm,
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// import FormCreate from './components/edit'
import FormEdit from './components/edit'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {user, accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        disabled: false,                                      
        error: false,    
        errorText: "",        
    }
    const [formState, setFormState] = useState(formStateDefault)
    const [toolbarState, setToolbarState] = useState({ create: true, delete: false, edit: false, archive: false, unarchive: false, refresh: true })
    const [dialogStateDeleteCustomer, setDialogStateDeleteCustomer] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    const [drawerCreateState, setDrawerCreateState] = useState(false)
    const [drawerEditState, setDrawerEditState] = useState({ open: false })
    const [resellers, setResellers] = useState([])
    const [listviewDataCustomers, setListviewDataCustomers] = useState([])
    const [listviewSelectionModelCustomers, setListviewSelectionModelCustomers] = useState([]) 
    const listviewColumnsCustomers = [
        { field: 'name', headerName: 'Navn', flex: 1.0 },        
        { field: 'email', headerName: 'Email', flex: 0.5 },                 
    ]
    
    if (isAdministrator(user)) {
        listviewColumnsCustomers.push (
            { field: 'resellerId', headerName: 'Forhandler', flex: 0.5,
                renderCell: (params) => {                
                    try {return resellers.find(o => o.id === params.value).name} catch (error) {return ""}
                },
            },
        )
    }

    useEffect(() => {
        handleRefresh ()            
    }, [])
    
    const handleCreate = () => {
        setDrawerCreateState({...drawerCreateState, open: true})                          
    }

    const handleEdit = () => {
        setDrawerEditState({...drawerEditState, open: true})
    }         

    const handleDelete = () => {
        setDialogStateDeleteCustomer({
            open: true,
            title: "Slet kunde",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Er du sikker på du vil slette denne kunde?
                </DialogContentText>),
            button1Text: "Nej",
            button2Text: "Ja",
            onConfirm: async () => {
                try {
                    let customerDelete = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + listviewSelectionModelCustomers, {
                        method: 'DELETE', 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }
                    })  
        
                    if (!customerDelete.ok)
                        throw new Error((await customerDelete.json()).error.code)
        
                    handleRefresh()
                } catch (error) {
                    handleError(error)
                }

                // fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + listviewSelectionModelCustomers, {
                //     method: 'DELETE', 
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': "Bearer "+ accessToken
                //     },           
                // })                
                // .then((data) => {

                //     handleRefresh ()                                    
                // })
                // .catch((error) => {                    
                //     handleError(error)
                // })
            },
            onDecline: () => {}
        })
    }

    const handleError = (error) => {
        setFormState({ ...formState, error: true, errorText: "Der opstod en uventet fejl." })
    }

    const handleRefresh = async () => {
        try {
            if (isAdministrator(user)) {
                let getResellers = await fetch(API_URL_PHONEZOLUTIONS.RESELLERS, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!getResellers.ok)
                    throw new Error((await getResellers.json()).error.code)

                setResellers(await getResellers.json())
            }        

            let getCustomers = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getCustomers.ok)
                throw new Error((await getCustomers.json()).error.code)

            setListviewDataCustomers(await getCustomers.json())  
        } catch (error) {            
            handleError(error)
        }        
    }
        
    const handleSelectionCustomers = (selection) => {
        setListviewSelectionModelCustomers(selection)            
        if (selection.length != 0)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleClickAwayCustomers = () => {}

    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateDeleteCustomer} setDialogState={setDialogStateDeleteCustomer}/>
            <SimpleUIDrawer state={drawerCreateState} setState={setDrawerCreateState} callback={handleRefresh}>
                <FormEdit/>
            </SimpleUIDrawer>   
            <SimpleUIDrawer state={drawerEditState} setState={setDrawerEditState} callback={handleRefresh} customerId={listviewSelectionModelCustomers}>
                <FormEdit/>
            </SimpleUIDrawer>
            <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        width: '100%'
                    }}
                >
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>             
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>                                    
                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                <SimpleUIListview columns={listviewColumnsCustomers} rows={listviewDataCustomers} onSelection={handleSelectionCustomers} onClickAway={handleClickAwayCustomers} rowsPerPageOptions={[100]} selectionModel={listviewSelectionModelCustomers}></SimpleUIListview>
            </Paper>    
        </React.Fragment>    
    )
}
