// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Stack,
    Button,    
    IconButton,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIListview
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import DialogEditAPICredentials from './dialogEditAPICredentials'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const [listviewSelectionModelAPICredentials, setListviewSelectionModelAPICredentials] = React.useState([]) 
    const listviewColumnsAPICredentials = [
        { field: 'title', headerName: 'Title', flex: 1.0 },                 
        { field: 'type', headerName: 'Type', flex: 0.3 },
        { field: 'id', headerName: '', flex: 0.3, storable: false,
            renderCell: params => {                
                return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton aria-label="edit" size="small" onClick={() => {handleOnAPICredentialEdit(params.value)}}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={() => {handleOnAPICredentialDelete(params.value)}}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>                        
                    </Stack>
                )
            }           
        },        
    ]   
    
    const [dialogEditAPICredentialsState, setDialogEditAPICredentialsState] = React.useState({
        open: false,
        data: null        
    })

    const dialogEditAPICrendentialsCallback = (data) => {
        let tempAPICredentials = [...props.apiCredentials]
        let index = tempAPICredentials.findIndex(o => o.id == data.id);

        if (index > -1) 
            tempAPICredentials[index] = {...data}
        else 
            tempAPICredentials.push ({...data})
        
        props.callback(tempAPICredentials)
    }    

    const handleOnAPICredentialAdd = (id) => {
        setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
            open: true,
            data: {}
        })
    }

    const handleOnAPICredentialEdit = (id) => {
        setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
            open: true,
            data: props.apiCredentials.find(o => { return o.id === id})
        })
    }

    const handleOnAPICredentialDelete = (id) => {
        props.callback(props.apiCredentials.filter(o => { return o.id !== id}))        
    }

    const handleOnSelectionAPICredentials = (selection) => {}
    const handleOnClickAwayAPICredentials = () => {}    
    
    return (
        <>
            <DialogEditAPICredentials state={dialogEditAPICredentialsState} setState={setDialogEditAPICredentialsState} callback={dialogEditAPICrendentialsCallback}/>
            <Box sx={{mt: 2}}>
                <Box sx={{height: "350px"}}>
                    <SimpleUIListview columns={listviewColumnsAPICredentials} rows={props.apiCredentials} onClickAway={handleOnClickAwayAPICredentials} onSelection={handleOnSelectionAPICredentials} rowsPerPageOptions={[100]} selectionModel={listviewSelectionModelAPICredentials}></SimpleUIListview>                        
                </Box>
                <Button sx={{mt: 1}} onClick={handleOnAPICredentialAdd}>Tilføj API Adgang</Button>
            </Box>  
        </>
    )
}