// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, memo, useCallback } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,        
    Grid,
    IconButton,    
    Tab,
    Tabs,
    CircularProgress,
    Switch,
    FormGroup,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon, NewReleasesRounded,     
    ContentCopy as CopyToClipboard,   
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
import {CopyField} from '@eisberg-labs/mui-copy-field'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
    SimpleUIChipBox,
    SimpleUIDragAndDropList,
    SimpleUIDialogListSelector,
    SimpleUIMaskedInputIPAddressCIDR
    
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Action from './action';
import {isAdministrator, isReseller, isCustomer, API_URL_PHONEZOLUTIONS} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MEMO -------
const TextFieldMemo = memo(TextField)
const SimpleUIChipBoxMemo = memo(SimpleUIChipBox)
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >   
        <div style={{ display: value === index ? 'block': 'none'}}>  
            {children}
        </div>
      </div>
    )
}

export default (props) => {
    const {drawerState, setDrawerState, receiverId, callback} = props
    const {user, accessToken } = SimpleUIAuthState()
    const formStateDefault = {
        isInitializing: true,
        mainButtonDisabled: true,
        mainButtonLoading: false,        
        error: false,    
        errorText: "",
    }
    const formDataDefault = {
        title: "",
        settings: {},
        actions: [],
    }
    const [formState, setFormState] = useState(formStateDefault)
    const [defaultData, setDefaultData] = useState()
    const [formData, setFormData] = useState(formDataDefault)
    const [tab, setTab] = React.useState(0)
    const [selectorOpenAction, setSelectorOpenAction] = React.useState(false)
    const [customers, setCustomers] = React.useState([])
    const [apiCredentials, setApiCredentials] = React.useState([])

    useEffect(async () => {
        if (drawerState.open && receiverId != "" && receiverId != null) {
            try {
                let receiverGet = await fetch(API_URL_PHONEZOLUTIONS.RECEIVERS + receiverId, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!receiverGet.ok)
                    throw new Error((await receiverGet.json()).error.code)

                let receiver = await receiverGet.json()

                setDefaultData(receiver)
                setFormData(receiver)

                let customerGet = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + receiver.customerId, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
            
                if (!customerGet.ok)
                    throw new Error((await customerGet.json()).error.code)
            
                setApiCredentials((await customerGet.json()).apiCredentials)
            } catch (error) {
                handleError(error)
            }
        } else {            
            if (isAdministrator(user) || isReseller(user)) {
                try {
                    let getCustomers = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
                        method: 'GET',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }            
                    })           

                    if (!getCustomers.ok)
                        throw new Error((await getCustomers.json()).error.code)

                    setCustomers((await getCustomers.json()))            
                } catch (error) {
                    handleError(error)
                }
            }
            // else {
            //     try {
            //         let getCustomers = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
            //             method: 'GET',
            //             headers: { 
            //                 'Content-Type': 'application/json',
            //                 'Authorization': "Bearer "+ accessToken
            //             }            
            //         })           

            //         if (!getCustomers.ok)
            //             throw new Error((await getCustomers.json()).error.code)

            //         setFormData(async (prevState) => {
            //             let newState = {...prevState, ["customerId"]: (await getCustomers.json())[0].customerId}                        
            //             return newState
            //         })
                    
            //     } catch (error) {
            //         handleError(error)
            //     }
            // }
        }

        setTimeout(() => {
            setFormState({ ...formState, isInitializing: false })           
        }, 400)
    }, [drawerState.open])

    useEffect(() => {
        let formValid = 0

        // Validate: CHANGED
        if (JSON.stringify({...defaultData}) === JSON.stringify({ ...formData})) {           
            formValid++
        }

        // Validate: CUSTOMERID
        if (!formData.customerId && !isCustomer(user))
            formValid++
        
        // Validate: TITLE
        if (formData.title.length == 0)
            formValid++
        
        setFormState({...formState, 
            error: false,    
            errorText: "",            
            mainButtonLoading: false,
            mainButtonDisabled: !!(formValid)
        })        
    }, [formData])

    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {            
            let receiverPost = await fetch(API_URL_PHONEZOLUTIONS.RECEIVERS, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({
                    customerId: formData.customerId,
                    title: formData.title
                })
            })

            if (!receiverPost.ok)
                throw new Error((await receiverPost.json()).error.code)
            
            let newReceiver = await receiverPost.json()
                                    
            let customerGet = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + newReceiver.customerId, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           
        
            if (!customerGet.ok)
                throw new Error((await customerGet.json()).error.code)
        
            setDefaultData(newReceiver)
            setFormData(newReceiver)
            setApiCredentials((await customerGet.json()).apiCredentials)

            if (callback)
                callback()

        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let featchPatch = await fetch(API_URL_PHONEZOLUTIONS.RECEIVERS + formData.id, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(
                    {...formData}
                )
            })  

            if (!featchPatch.ok)
                throw new Error((await featchPatch.json()).error.code)
            
            setDefaultData({...formData})
            setFormData({...formData})

            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }                    
    }

    const handleAddAction = (result) => {
        let newActions = [...formData.actions]

        newActions.push({
            id: uuidv4(),
            type: result[0],
            settings: {}
        })

        setFormData(prevState => {
            let newState = {...prevState, ["actions"]: newActions}
            return newState
        })
    }

    const handleError = (error) => {
        setFormState({ ...formState, mainButtonLoading: false, isInitializing: false })

        if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der findes allerede en forhandler med denne e-mail adresse." })
        else
            setFormState({ ...formState, createLoading: false, error: true, errorText: "An unknown error occurred." })
    }

    const handleClose = (event, reason) => {
        setFormState(formStateDefault)
        setFormData(formDataDefault)
        setDrawerState({...drawerState, open: false})
    }

    const handleOnChangeTab = useCallback((event, newValue) => {
        setTab(newValue);
    }, [])
    
    const handleOnChange = (event) => {
        const id = (event.target.name || event.target.id)
        const value = event.target.value
       
        setFormData(prevState => {
            let newState = {...prevState, [id]: value}            
            return newState
        })

        return true
    }

    const handleOnChangeActions = useCallback((actions) => {
        // if (isMounted.current) {
            setFormData(prevState => {
                let newState = {...prevState, ["actions"]: actions}
                return newState
            })
        // }
    }, [])

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }
 
    const handleOnClickAddAction = useCallback((actions) => {
        setSelectorOpenAction(true)
    }, [])

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 
    
    return (
        <>
            <SimpleUIDialogListSelector 
                open={selectorOpenAction}
                setOpen={setSelectorOpenAction}
                
                title="Tilføj handling"
                button1Text="Luk"
                button2Text="Tilføj"

                columns={[{ field: 'title', headerName: 'Title', flex: 1 }]}
                rows={[
                    {id: "function", title: "Funktion"},
                    {id: "webhook", title: "Webhook"},
                    {id: "oneconnect", title: "UniTel - OneConnect"},
                    {id: "sms2go", title: "SMS2GO"},
                    {id: "smtp2go", title: "SMTP2GO"},
                    {id: "teams", title: "TEAMS"}
                ]}

                onSelect={handleAddAction}
            />      

            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>
            
            <Paper elevation={3} sx={{ p: 2}}>
                <Grid container spacing={0} sx={{mt: 0}}>
                    <Grid item xs={10}>
                        <Typography variant="h6" display="block" sx={{mb: 1}}>
                            {formData.id ? "Redigere modtager" : "Opret modtager" }
                        </Typography>                              
                    </Grid>                                    
                    <Grid item xs={2} justifyContent={"flex-end"}>
                        <Box display="flex" justifyContent="flex-end">
                            <FormGroup>
                                <FormControlLabel control={                                                                                                                                        
                                    <Switch
                                        name="enabled"
                                        size="small"
                                        checked={formData.enabled}
                                        disabled={!formData.id}
                                        onChange={(event) => handleOnChange({target: {name: event.target.name, value: event.target.checked}})}
                                    />
                                } label="Aktiv" />                              
                            </FormGroup>
                        </Box>
                    </Grid>    
                    {formData.id ? (
                        <Grid item xs={12}>
                            {/* <Typography variant="subtitle2" display="block" sx={{mb: 1}}  style={{ fontSize: "12px" }}>                                
                                    {formData.helloURL}
                            </Typography>
                            <IconButton size="small" onClick={() => {navigator.clipboard.writeText(formData.helloURL)}}>
                                <CopyToClipboard></CopyToClipboard>
                            </IconButton> */}
                            <CopyField sx={{mb: 1}} fullWidth
                                label="Hello URL"
                                value={formData.helloURL}                                                                
                            />
                        </Grid>     
                    ):(
                        <></>    
                    )}                                                      
                </Grid>

                {!formData.id && !isCustomer(user) ? (
                    <FormControl sx={{mb: 1}}
                    fullWidth 
                    required                                            
                >
                    <InputLabel>Kunde</InputLabel>
                    <Select                                  
                        label="Kunde"
                        name="customerId"                    
                        // value={formData.customerId}
                        onChange={handleOnChange}
                    >                                
                        {customers.map((customer) =>   
                            <MenuItem value={customer.id}>{customer.name}</MenuItem>
                        )}      
                    </Select>
                </FormControl>
                ):(
                    <></>    
                )}
                
                <TextFieldMemo sx={{mb: 1}}
                    name="title"
                    label="Title"
                    value={formData.title}
                    required                                    
                    fullWidth
                    margin="dense"                                                
                    variant="outlined"
                    autoComplete="off"                    
                    onChange={handleOnChange}                   
                />

                {formData.id ? (
                    <>
                        <SimpleUIChipBoxMemo sx={{mt: 1, mb: 1}}
                            name="allowedFrom"
                            label={"Tilladt fra IP(er)"}                                                         
                            variant="outlined"
                            freeSolo
                            disableBackspaceRemoveOption                            
                            value={formData.settings.allowFrom}                                                    
                            inputComponent={SimpleUIMaskedInputIPAddressCIDR}
                            onChange={handleOnChange}
                        />
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab} onChange={handleOnChangeTab}>
                                    <Tab label="Handlinger"/>
                                    <Tab label="Log"/>
                                    {/* <Tab label="Debug"/> */}
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={tab} index={0}>
                                <Box sx={{mt: 2}}>
                                    <SimpleUIDragAndDropList 
                                        items={formData.actions} 
                                        onChange={handleOnChangeActions} 
                                        component={Action}
                                        componentProps={{
                                            apiCredentials: apiCredentials
                                        }}
                                    />
                                    <Button onClick={handleOnClickAddAction}>Tilføj handling</Button>
                                </Box>  
                            </CustomTabPanel>
                            <CustomTabPanel value={tab} index={1}>
                                <Box component="div" sx={{ overflow: 'scroll', height: '450px' }}>
                                    {formData.lastTriggerLog.map((logEntry, i) => {     
                                        return (<Typography>{logEntry}</Typography>) 
                                    })}
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={tab} index={2}>
                            </CustomTabPanel>
                        </Box>
                    </>    
                ):(
                    <></>    
                )}
            </Paper>
                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Luk</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>                                
                        {formData.id ? "Gem" : "Opret" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </>
    )
}
