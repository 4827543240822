// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    IconButton,    
    CircularProgress,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import ListviewAPICredentials from '../../customers/components/listviewAPICredentials'
import DialogEditAPICredentials from '../../customers/components/dialogEditAPICredentials'
import {API_URL_PHONEZOLUTIONS} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {drawerState, setDrawerState, callback} = props
    const {user, accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        isInitializing: true,
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,
        error: false,    
        errorText: "",
        errorName: false,
        errorNameText: "",
        errorEmail: false,
        errorEmailText: "",        
    }    
    const formValueDefault = {
        name: "",
        email: "",        
    }        
    const [formState, setFormState] = useState(formStateDefault)   
    const [defaultData, setDefaultData] = useState()
    const [formData, setFormData] = useState(formValueDefault)    

    useEffect(async () => {
        if (drawerState.open && user.data.customerId != "" && user.data.customerId != null) {    
            try {
                let customerGet = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + user.data.customerId, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!customerGet.ok)
                    throw new Error((await customerGet.json()).error.code)

                let reseller = await customerGet.json()

                setDefaultData(reseller)
                setFormData(reseller)                
            } catch (error) {
                handleError(error)
            }
        } else { 

        }

        setTimeout(() => {
            setFormState({ ...formState, isInitializing: false })           
        }, 400)
                    
    }, [drawerState.open])

    useEffect(() => {
        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify({...defaultData}) === JSON.stringify({ ...formData})) {            
            valid++
        }

        setFormState({...formState, 
            error: false,    
            errorText: "",
            errorName: false,
            errorNameText: "",
            errorEmail: false,
            errorEmailText: "",
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid)
        })
        
    }, [formData])
    
    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let resellerPost = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })


            if (!resellerPost.ok)
                throw new Error((await resellerPost.json()).error.code)

            let newResellerId = (await resellerPost.json()).id

            setDefaultData({...formData, 
                id: newResellerId,
                apiCredentials: []
            })
            setFormData({...formData, 
                id: newResellerId,
                apiCredentials: []
            })

            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let featchPatch = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + user.data.customerId, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })  

            if (!featchPatch.ok)
                throw new Error((await featchPatch.json()).error.code)

            setDefaultData({...formData})
            setFormData({...formData})
            
            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }    
    }

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     
        if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der findes allerede en kunde med denne e-mail adresse." })
        else
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
    }

    const handleClose = () => {
        setFormState(formStateDefault)
        setFormData(formValueDefault)
        
        setDrawerState({...drawerState, open: false})
    }
    
    const [dialogEditAPICredentialsState, setDialogEditAPICredentialsState] = React.useState({
        open: false,
        data: null        
    })

    const dialogEditAPICrendentialsCallback = (data) => {
        let tempAPICredentials = [...formData.apiCredentials]
        let index = tempAPICredentials.findIndex(o => o.id == data.id);

        if (index > -1) 
            tempAPICredentials[index] = {...data}
        else 
            tempAPICredentials.push ({...data})
        
        setFormData({...formData, apiCredentials: tempAPICredentials})
    }    

    const handleonAPICredentialsCallback = (data) => {
        setFormData({...formData, apiCredentials: data})
    }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <>  
            <DialogEditAPICredentials state={dialogEditAPICredentialsState} setState={setDialogEditAPICredentialsState} callback={dialogEditAPICrendentialsCallback}/>

            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
                <Typography variant="h6" sx={{mb: 1}}>
                    API adgang
                </Typography>
              
                <Box sx={{mt: 2}}>
                    <ListviewAPICredentials apiCredentials={formData.apiCredentials} callback={handleonAPICredentialsCallback} ></ListviewAPICredentials>
                </Box>                                
            </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Luk</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Gem" : "Opret" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </>
    )
}