// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useCallback, useEffect, useRef, memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Collapse,
    Fade,
    Typography,
    TextField,
    IconButton,
    Button,
    Switch
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    DragIndicator as DragHandleIcon,    
    Delete as DeleteIcon
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- QUILL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
// import dayjs, { Dayjs } from 'dayjs'
// import dayjs from 'dayjs'
// import 'dayjs/locale/da'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {        
// } from './../../../simpleUI'
// ----------------------------------------------------------------------
import Function from './function'
import Webhook from './webhook'
import Oneconnect from './oneconnect'
import SMS2Go from './sms2go'
import SMTP2Go from './smtp2go'
import Teams from './teams'
// --------------------------------------------------------- Memomize ---
const TextFieldMemo = memo(TextField)
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
// const quillModules = {
//     toolbar: [          
//         ['bold', 'italic', 'underline','strike', 'blockquote'],
//         [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
//     ],
// }
// ----------------------------------------------------------------------
    
export default memo((props) => {
    const {item, itemIndex, onItemChange, provided} = props
    const isMounted = useRef(false)    
    const [collapsed, setCollapsed] = useState(true)
    const [deleted, setDeleted] = useState(false)
    const [formData, setFormData] = useState(item)

    useEffect(() => {
        if (isMounted.current) {
            onItemChange(itemIndex, formData)
        } else {            
            setCollapsed(true)
            isMounted.current = true
        }    
    }, [formData])

    const handleOnChange = useCallback((event) => {
        const id = (event.target.name || event.target.id) 
        const value = event.target.value

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}
            return newState
        })
        
        return true
    }, [])

    const handleOnClickCollaps = () => {
        setCollapsed(!collapsed)
    }   

    const handleOnClickDelete = () => {
        setDeleted(true)        
        setTimeout(() => {
            onItemChange(itemIndex, null)
        }, 500)
    }
                
    return (
        <Collapse in={!deleted}>
            <Fade appear={false} in={!deleted}>
                <Grid container spacing={1} sx={{mb: 1}} >           
                <Grid item xs={1} >
                    <Box sx={{height: "80px", display: "flex", justifyContent: "center", flexDirection: "column"}} >
                        <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon/>
                        </IconButton>                                                                                                                               
                    </Box>
                </Grid>
                    <Grid item xs={11} >
                        <Box sx={{border: 1, borderRadius: 1, borderColor: 'grey.400', p: 2, backgroundColor: '#ffffff' }}>
                            <Grid container spacing={0} sx={{mt: 0}}>
                                <Grid item xs={10}>
                                    <Typography variant='body' noWrap>
                                        {formData.type.toUpperCase()}
                                    </Typography>
                                    <Typography variant='subtitle2' noWrap sx={{display: "flex"}}>
                                        {formData.description || ""}
                                    </Typography>                                    
                                </Grid>                                    
                                <Grid item xs={2} justifyContent={"flex-end"}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Switch sx={{mt: "9px"}}
                                            name="enabled"
                                            size="small"
                                            checked={formData.enabled}                                            
                                            onChange={(event) => handleOnChange({target: {name: event.target.name, value: event.target.checked}})}
                                        />    
                                        <IconButton onClick={handleOnClickCollaps}>
                                            { collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                                        </IconButton>                                        
                                    </Box>
                                </Grid>                                                               
                            </Grid>
                            <Collapse in={!collapsed}>
                                    {/* Details */}
                                    <TextFieldMemo sx={{mt: 2, mb: 2}}
                                        name="description"
                                        label="Beskrivelse"                                                                                        
                                        fullWidth
                                        value={formData.description}
                                        onChange={handleOnChange}
                                    />                                

                                    {/* TYPE: WEBHOOK */}
                                    {formData.type == "webhook" ? (
                                        <Webhook
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />                                         
                                    ):(
                                        <></>
                                    )}

                                    {/* TYPE: FUNCTION */}
                                    {formData.type == "function" ? (
                                        <Function 
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />                                         
                                    ):(
                                        <></>
                                    )}

                                    {/* TYPE: ONECONNECT */}
                                    {formData.type == "oneconnect" ? (
                                        <Oneconnect 
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />                                
                                    ):(
                                        <></>
                                    )}                                    

                                    {/* TYPE: SMS2GO */}
                                    {formData.type == "sms2go" ? (
                                        <SMS2Go 
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />
                                    ):(
                                        <></>
                                    )}

                                    {/* TYPE: SMTP2GO */}
                                    {formData.type == "smtp2go" ? (
                                        <SMTP2Go 
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />
                                    ):(
                                        <></>
                                    )}

                                    {/* TYPE: TEAMS */}
                                    {formData.type == "teams" ? (
                                        <Teams
                                            settings={formData.settings} 
                                            apiCredentials={props.apiCredentials}
                                            onChange={(value) => handleOnChange({target: {name: "settings", value: value}})}
                                        />                                         
                                    ):(
                                        <></>
                                    )}                                    

                                    {/* Buttom Menu */}
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button 
                                            variant="contained" 
                                            startIcon={<DeleteIcon fontSize="inherit"/>} 
                                            size="small" 
                                            color="error"
                                            onClick={handleOnClickDelete}
                                        >
                                            Fjern
                                        </Button>
                                    </Box>
                            </Collapse> 
                        </Box>
                    </Grid>            
                </Grid>    
            </Fade>   
        </Collapse>                  
    )
}) 