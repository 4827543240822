import {
    AuthProvider as SimpleUIAuthProvider, 
    useAuthState as SimpleUIAuthState, 
    useAuthDispatch as SimpleUIAuthDispatch, 
    authInitialize as SimpleUIAuthInitialize, 
    authSignIn as SimpleUIAuthSignIn, 
    authSignOut as SimpleUISignOut, 
    authRefreshTokens as SimpleUIAuthRefreshTokens 
} from './components/authProvider'

import SimpleUIApp from "./components/app.js"

import SimpleUIAppBar from "./components/appbar"
import SimpleUISideBarNav from "./components/sidebarnav"

import SimpleUIDrawer from "./components/Drawer"
import SimpleUIListview from "./components/Listview"
import SimpleUIChipBox from "./components/ChipBox"
import SimpleUIDragAndDropList from "./components/DragAndDropList"

import SimpleUIDialogListSelector from "./components/DialogListSelector"

import {
    IPAddressCIDR as SimpleUIMaskedInputIPAddressCIDR
} from "./components/MaskedInput"

import SimpleUIDialogConfirm from './components/DialogConfirm'
import SimpleUIDialogError from "./components/DialogError"

import SimpleUIFormPasswordSet from "./components/gate/formPasswordSet"
import SimpleUIFormPasswordForgot from "./components/gate/formPasswordForgot"
import SimpleUIFormActivation from "./components/gate/formActivation"
import SimpleUIFormActivationReset from "./components/gate/formActivationReset"

import SimpleUIPageGateSignIn from "./pages/gate/signIn"
import SimpleUIPageGatePasswordSet from "./pages/gate/passwordSet"
import SimpleUIPageGatePasswordForgot from "./pages/gate/passwordForgot"
import SimpleUIPageGateActivation from "./pages/gate/activation"
import SimpleUIPageGateActivationReset from "./pages/gate/activationReset"

import SimpleUILogger from "./components/logger"



// Dashboard
// import Dashboard from "./pages/dashboard"
// import DashboardIcon from '@mui/icons-material/Dashboard'

// Users
import Users from "./pages/users/"
import UsersIcon from '@mui/icons-material/People'


const SimpleUIPageUsers = {
    path: "/users",
    Main: Users,
    sidebarnav: {
        icon: UsersIcon,
        label: "Users"
    },
    restrictions: {
        usergroups: [
            "2a4707ae-ab17-47a7-b519-51f75a60f341"
        ]
    }

}

export { 
    // AuthProvider
    SimpleUIAuthProvider,
    SimpleUIAuthState,
    SimpleUIAuthDispatch,
    SimpleUIAuthInitialize,
    SimpleUIAuthSignIn,
    SimpleUISignOut,
    SimpleUIAuthRefreshTokens,

    // App
    SimpleUIApp,

    // APPUI
    SimpleUIAppBar, 
    SimpleUISideBarNav,

    SimpleUIDrawer,
    SimpleUIListview,
    SimpleUIChipBox,
    SimpleUIDragAndDropList,
    SimpleUIDialogListSelector,
    
    SimpleUIMaskedInputIPAddressCIDR,

    // APPDIALOG
    SimpleUIDialogConfirm,
    SimpleUIDialogError,
    
    // APPFORM    
    SimpleUIFormPasswordSet,
    SimpleUIFormPasswordForgot,
    SimpleUIFormActivation,
    SimpleUIFormActivationReset,

    // SimpleUISignIn,
    // SimpleUIForgotPassword,
    // SimpleUIResetPassword,


    // LOGGER
    SimpleUILogger,

    // PAGES
    SimpleUIPageGateSignIn,
    SimpleUIPageGatePasswordSet,
    SimpleUIPageGatePasswordForgot,
    SimpleUIPageGateActivation,
    SimpleUIPageGateActivationReset,
    
    SimpleUIPageUsers,
}