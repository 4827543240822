import React from "react"
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom"

// MUI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'

// Authentication
import {
    authInitialize, 
    
    AuthProvider, 
    useAuthState, 
    useAuthDispatch 
} from "./authProvider"

// Components
import {
        SimpleUIAppBar, 
        SimpleUISideBarNav, 
        
        SimpleUIPageGateSignIn,
        SimpleUIPageGatePasswordSet,
        SimpleUIPageGatePasswordForgot,
        SimpleUIPageGateActivation,
        SimpleUIPageGateActivationReset,
} from "../"


// Pages
// import pages from "../pages/pages.js"

const mdTheme = createTheme({
    palette: {
        mode: 'light',
      },      
    }
)

function App(props) {                
	const { authorized, authInitializing } = useAuthState()    
    const { pages, settings } = props

    const {user, accessToken} = useAuthState()

    let t = []
    if (authorized) {
    pages.forEach(element => {        
        if (element.restrictions && settings.useAuthentication) {            
            element.restrictions.usergroups.forEach(usergroupId => {
                if (user.usergroups.find(o => o.id === usergroupId)) {
                    console.log (element.sidebarnav.label)
                    t.push({...element})
                }    
            })
        } else {
            console.log (element.sidebarnav.label)
            t.push(element)
        }
    })
    }

        
    const dispatch = useAuthDispatch()

    React.useEffect(() => {        
        authInitialize(dispatch, {})
    }, [])

    // React.useEffect(() => {        
    //     if (authorized) {
    //         t = []
           
    //     }
        
    // }, [authorized])
    
    return (
        <BrowserRouter>
            <ThemeProvider theme={mdTheme}>
                {
                    authInitializing
                    ?
                        <Box sx={{ display: 'flex' }}>                    
                            <Box component="main"                            
                                sx={{
                                    backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                                    flexGrow: 1,                                
                                    height: 'calc(100vh)',
                                    width: '100%',
                                    overflow: 'auto',
                                }}>                    
                                <Container maxWidth="false" sx={{ mt: 4, mb: 4, height: 'calc(100vh)' }}
                                style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center',   justifyContent: 'center',}}>                    
                                    <CircularProgress style={{width: '100px', height: '100px'}}/>
                                </Container>
                            </Box>                
                        </Box>
                    :

                        (!authorized && settings.useAuthentication)
                        ? 
                            <Routes>
                                <Route path="*" element={<Navigate to="/" />} />
                                <Route path={"/"} element={<SimpleUIPageGateSignIn settings={settings}/>}/>
                                <Route path={"/resetpassword"} element={<SimpleUIPageGatePasswordSet/>}/>
                                <Route path={"/forgotpassword"} element={<SimpleUIPageGatePasswordForgot/>}/>                                
                                <Route path={"/activation"} element={<SimpleUIPageGateActivation/>}/>
                                <Route path={"/resetactivation"} element={<SimpleUIPageGateActivationReset/>}/>
                            </Routes>
                        :            
                            <Box sx={{ display: 'flex' }}>
                                <CssBaseline />

                                {!settings.hideAppBar && <SimpleUIAppBar settings={settings}/>}
                                {!settings.hideNavBar && <SimpleUISideBarNav pages={t} settings={settings}/>}

                                <Box component="main"
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                                        flexGrow: 1,
                                        marginTop: settings.hideAppBar ? '0px' : '64px',
                                        height: settings.hideAppBar ? '100vh' : 'calc(100vh - 64px)',
                                        width: '100%',
                                        overflow: 'auto',
                                    }}>                    
                                    <Container disableGutters maxWidth="false" sx={{ 
                                        // mt: 4, 
                                        // mb: 4, 
                                        height: settings.hideAppBar ? '100vh' : 'calc(100vh - 64px)'
                                    }}>
                                        <Routes>
                                            <Route path="*" element={<Navigate to="/" />} />
                                            {t.map(({ path, Main }) => (
                                                <Route path={path} element={<Main/>}/>
                                            ))}
                                        </Routes>                                            
                                    </Container>
                                </Box>                
                            </Box>
                }
            </ThemeProvider>      
        </BrowserRouter>           
    )
}

export default function SimpleUIApp(props) {
    const { pages, settings } = props
    
    return (
        <AuthProvider> 
            <App pages={pages} settings={settings}/>
        </AuthProvider>           
    )
}