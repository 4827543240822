import * as React from 'react';
import { useCallback, useState, useEffect, useRef, memo } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { v4 as uuidv4 } from 'uuid';

export default (props) => {              
    // const {items: defaultItems, onChange, component} = props    
    const {items, onChange, component, componentProps} = props    
    const isMounted = useRef(false)    
    
    // const prepareItems = (t) => {
    //     let result = []                
    //     t.map((item, index) => {
    //         // console.log (item.jobTitle)
    //         result.push({
    //             // id: uuidv4()+`-${index}`,
    //             id: `item-${index}`,
    //             content: item
    //         })
    //     })
    //     return result
    // }

    const[itemList, setItemList] = useState(items)

    useEffect(() => {
        if (isMounted.current && onChange) {                       
            onChange(itemList)
        } else {
            isMounted.current = true
        }    
    }, [itemList])

    useEffect(() => {
        if (isMounted.current && onChange) {                    
            setItemList(items)
        } else {
            isMounted.current = true
        }    
    }, [items])

    const onDragEnd = (props) =>{
        const {destination, source, draggableId } = props

        if (!destination) {
            return
        }

        if (
            destination.draggableId === source.draggableId &&
            destination.index === source.index
        ) {
            return
        }

        const item = itemList[source.index]
        const newItems = Array.from(itemList)
        newItems.splice(source.index, 1)
        newItems.splice(destination.index, 0, item)

        setItemList(newItems)
    }

    const onItemChange = useCallback((id, data) => {
        if (data == null) {
            setItemList(prevState => {
                return prevState.filter((item) => item.id !== id)                                
            })            
        } else {
            setItemList(prevItems => prevItems.map((item) => {
                return item.id !== id ? item : data })
            )
        }
    }, [])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Column id="column-1" items={itemList} onItemChange={onItemChange} component={component} componentProps={componentProps}/>
        </DragDropContext>
    )
}

const Column = memo((props) => {
    const {id, items, onItemChange, component, componentProps} = props
 
    return (         
        <Droppable droppableId={id}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {items.map((item, index) => 
                        <Item key={item.id} draggableId={item.id} index={index} item={item} onItemChange={onItemChange} component={component} componentProps={componentProps} />
                    )}
                    {provided.placeholder}
                </div>
            )}                
        </Droppable>            
    )
})

const Item = memo((props) => {
    const {index, draggableId, item, onItemChange, component: Component, componentProps} = props

    return (
        <Draggable draggableId={draggableId} index={index}>
            {(provided) =>(                    
                <div
                    ref={provided.innerRef}    
                    {...provided.draggableProps}                                           
                >
                
                    <Component provided={provided} item={item} itemIndex={draggableId} onItemChange={onItemChange} {...componentProps}/>
                </div>
            )}            
        </Draggable>
    )   
})

