// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,    
    Stack,
    DialogContentText,
    IconButton,
    Collapse,
    Alert
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,    
    Close as CloseIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {API_URL_PHONEZOLUTIONS} from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIListview,
    SimpleUIDrawer,
    SimpleUIDialogConfirm,
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import FormEdit from './components/edit'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const {accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        disabled: false,                                      
        error: false,    
        errorText: "",        
    }
    const [formState, setFormState] = useState(formStateDefault)
    const [toolbarState, setToolbarState] = React.useState({ create: true, delete: false, edit: false, refresh: true })
    const [dialogStateDeleteReseller, setDialogStateDeleteReseller] = React.useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    const [drawerCreateState, setDrawerCreateState] = React.useState(false)
    const [drawerEditState, setDrawerEditState] = React.useState({ open: false })
    const [listviewDataResellers, setListviewDataResellers] = useState([])
    const [listviewSelectionModelResellers, setListviewSelectionModelResellers] = React.useState([]) 
    const listviewColumnsResellers = [
        { field: 'name', headerName: 'Navn', flex: 1.0 },        
        { field: 'email', headerName: 'Email', flex: 0.5 },                 
    ]   

    useEffect(() => {
        handleRefresh ()            
    }, [])

    const handleCreate = () => {
        setDrawerCreateState({...drawerCreateState, open: true})                          
    }

    const handleEdit = () => {
        setDrawerEditState({...drawerEditState, open: true})
    }         

    const handleDelete = () => {
        setDialogStateDeleteReseller({
            open: true,
            title: "Slet forhandler",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Er du sikker på du vil slette denne forhandler?
                </DialogContentText>),
            button1Text: "Nej",
            button2Text: "Ja",
            onConfirm: () => {
                fetch(API_URL_PHONEZOLUTIONS.RESELLERS + listviewSelectionModelResellers, {
                    method: 'DELETE', 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    },           
                })                
                .then(() => {
                    handleRefresh ()                                    
                })
                .catch((error) => {
                    handleError(error)
                })
            },
            onDecline: () => {}
        })
    }
    
    const handleRefresh = () => {
        fetch(API_URL_PHONEZOLUTIONS.RESELLERS, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
        })
          .then((data) => data.json())
          .then((data) => setListviewDataResellers(data))
    }

    const handleError = (error) => {
        setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
    }
    
    const handleSelectionResellers = (selection) => {
        setListviewSelectionModelResellers(selection)            
        if (selection.length != 0)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleClickAwayResellers = () => {}

    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateDeleteReseller} setDialogState={setDialogStateDeleteReseller}/>
            <SimpleUIDrawer state={drawerCreateState} setState={setDrawerCreateState} callback={handleRefresh}>
                <FormEdit/>
            </SimpleUIDrawer>   
            <SimpleUIDrawer state={drawerEditState} setState={setDrawerEditState} callback={handleRefresh} resellerId={listviewSelectionModelResellers}>
                <FormEdit/>
            </SimpleUIDrawer>
            <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        width: '100%'
                    }}
                >            
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>                                    
                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                <SimpleUIListview columns={listviewColumnsResellers} rows={listviewDataResellers} onSelection={handleSelectionResellers} onClickAway={handleClickAwayResellers} rowsPerPageOptions={[100]} selectionModel={listviewSelectionModelResellers}></SimpleUIListview>
            </Paper>    
        </React.Fragment>    
    )
}
