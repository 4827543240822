// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tabs,
    Tab
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Edit as EditIcon,
    Delete as DeleteIcon,
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
    SimpleUIListview
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import ListviewAPICredentials from './listviewAPICredentials'
import DialogEditAPICredentials from './dialogEditAPICredentials'
import {isAdministrator, isReseller, API_URL_PHONEZOLUTIONS} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >   
        <div style={{ display: value === index ? 'block': 'none'}}>  
            {children}
        </div>
      </div>
    )
}

export default (props) => {
    const {drawerState, setDrawerState, customerId, callback} = props
    const {user, accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        isInitializing: true,
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,
        error: false,    
        errorText: "",
        errorName: false,
        errorNameText: "",
        errorEmail: false,
        errorEmailText: "",        
    }
    const formValueDefault = {
        name: "",
        email: "",        
    }        
    const [formState, setFormState] = useState(formStateDefault)   
    const [defaultData, setDefaultData] = useState()
    const [formData, setFormData] = useState(formValueDefault)    
    const [resellers, setResellers] = React.useState([])

    const [tab, setTab] = React.useState(0)

    const [listviewSelectionModelAPICredentials, setListviewSelectionModelAPICredentials] = React.useState([]) 
    const listviewColumnsAPICredentials = [
        { field: 'title', headerName: 'Title', flex: 1.0 },                 
        { field: 'type', headerName: 'Type', flex: 0.3 },
        { field: 'id', headerName: '', flex: 0.3, storable: false,
            renderCell: params => {                
                return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton aria-label="edit" size="small" onClick={() => {handleOnAPICredentialEdit(params.value)}}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={() => {handleOnAPICredentialDelete(params.value)}}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>                        
                    </Stack>
                )
            }           
        },        
    ]   

    useEffect(async () => {
        if (drawerState.open && customerId != "" && customerId != null) {
            try {
                let customerGet = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + customerId, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!customerGet.ok)
                    throw new Error((await customerGet.json()).error.code)

                let reseller = await customerGet.json()

                setDefaultData(reseller)
                setFormData(reseller)                
            } catch (error) {
                handleError(error)
            }
        }   
        else {            
            
            // else {
            //     // try {
            //     //     let getResellers = await fetch(API_URL_PHONEZOLUTIONS_RESELLERS, {
            //     //         method: 'GET',
            //     //         headers: { 
            //     //             'Content-Type': 'application/json',
            //     //             'Authorization': "Bearer "+ accessToken
            //     //         }            
            //     //     })           

            //     //     if (!getResellers.ok)
            //     //         throw new Error((await getResellers.json()).error.code)

            //     //     setFormData(async (prevState) => {
            //     //         let newState = {...prevState, ["resellerId"]: (await getResellers.json())[0].id}
            //     //         return newState
            //     //     })
                    
            //     // } catch (error) {
            //     //     handleError(error)
            //     // }
            // }
        }

        if (isAdministrator(user)) {
            try {
                let getResellers = await fetch(API_URL_PHONEZOLUTIONS.RESELLERS, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!getResellers.ok)
                    throw new Error((await getResellers.json()).error.code)
                    
                setResellers((await getResellers.json()))
            } catch (error) {
                handleError(error)
            }
        }

        setTimeout(() => {
            setFormState({ ...formState, isInitializing: false })           
        }, 400)
                    
    }, [drawerState.open])

    useEffect(() => {
        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify({...defaultData}) === JSON.stringify({ ...formData})) {            
            valid++
        }

        // Validate: RESELLER
        if (!formData.resellerId && !isReseller(user))
            valid++
        
        // Validate: NAME
        if (formData.name.length == 0)
            valid++

        // Validate: EMAIL
        if (!emailValidator.validate(formData.email))
            valid++
        
        setFormState({...formState, 
            error: false,    
            errorText: "",
            errorName: false,
            errorNameText: "",
            errorEmail: false,
            errorEmailText: "",
            mainButtonLoading: false,
            mainButtonDisabled: !!(valid)
        })
        
    }, [formData])
    
    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let resellerPost = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })


            if (!resellerPost.ok)
                throw new Error((await resellerPost.json()).error.code)

            let newResellerId = (await resellerPost.json()).id

            setDefaultData({...formData, 
                id: newResellerId,
                apiCredentials: []
            })
            setFormData({...formData, 
                id: newResellerId,
                apiCredentials: []
            })

            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let featchPatch = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS + formData.id, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })  

            if (!featchPatch.ok)
                throw new Error((await featchPatch.json()).error.code)

            setDefaultData({...formData})
            setFormData({...formData})
            
            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }    
    }

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
       
        // console.log (id +" "+ value)

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}            
            return newState
        })

        return true
    }

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }

    const handleOnChangeTab = (event, newValue) => {
        setTab(newValue);
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                     
        if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der findes allerede en kunde med denne e-mail adresse." })
        else
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
    }

    const handleClose = () => {
        setFormState(formStateDefault)
        setFormData(formValueDefault)
        
        setDrawerState({...drawerState, open: false})
    }
    
    const [dialogEditAPICredentialsState, setDialogEditAPICredentialsState] = React.useState({
        open: false,
        data: null        
    })

    const dialogEditAPICrendentialsCallback = (data) => {
        let tempAPICredentials = [...formData.apiCredentials]
        let index = tempAPICredentials.findIndex(o => o.id == data.id);

        if (index > -1) 
            tempAPICredentials[index] = {...data}
        else 
            tempAPICredentials.push ({...data})
        
        setFormData({...formData, apiCredentials: tempAPICredentials})
    }    

    const handleOnAPICredentialAdd = (id) => {
        setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
            open: true,
            data: {}
        })
    }

    const handleOnAPICredentialEdit = (id) => {
        setDialogEditAPICredentialsState({...setDialogEditAPICredentialsState,
            open: true,
            data: formData.apiCredentials.find(o => { return o.id === id})
        })
    }

    const handleOnAPICredentialDelete = (id) => {
        setFormData({...formData, apiCredentials: formData.apiCredentials.filter(o => { return o.id !== id})})
    }

    const handleonAPICredentialsCallback = (data) => {
        setFormData({...formData, apiCredentials: data})
    }

    const handleOnSelectionAPICredentials = (selection) => {}
    const handleOnClickAwayAPICredentials = () => {}    

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <>  
            <DialogEditAPICredentials state={dialogEditAPICredentialsState} setState={setDialogEditAPICredentialsState} callback={dialogEditAPICrendentialsCallback}/>

            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse>  
            
            <Paper elevation={3} sx={{ p: 2}}>
                <Typography variant="h6" sx={{mb: 1}}>
                    {formData.id ? "Redigere kunde" : "Opret kunde" }
                </Typography>

                {isAdministrator(user) ? (
                    <FormControl
                        fullWidth 
                        required                                            
                        sx={{mb: 1}}
                        
                    >
                        <InputLabel>Forhandler</InputLabel>
                        <Select                                  
                            label="Forhandler"
                            name="resellerId"                    
                            value={formData.resellerId}
                            onChange={handleOnChange}
                            disabled={((isReseller(user)) ? true : false)}                                
                        >                                
                            {resellers.map((reseller) =>   
                                <MenuItem value={reseller.id}>{reseller.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                ):(
                    <></>    
                )}

                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Navn"
                    fullWidth                    
                    variant="outlined"
                    autoComplete="off"
                    value={formData.name}
                    onChange={handleOnChange}                    
                />

                <TextField sx={{mb: 1}}
                    required                             
                    margin="dense"
                    id="email"
                    label="E-mail"
                    fullWidth                    
                    variant="outlined"
                    autoComplete="off"
                    value={formData.email}
                    onChange={handleOnChange}                    
                />

                {formData.id ? (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={handleOnChangeTab}>
                                <Tab label="API Adgang"/>                                            
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tab} index={0}>
                            <Box sx={{mt: 2}}>
                                <ListviewAPICredentials apiCredentials={formData.apiCredentials} callback={handleonAPICredentialsCallback} ></ListviewAPICredentials>
                            </Box>  
                        </CustomTabPanel>                                    
                    </Box>
                ):(
                    <></>    
                )}
            </Paper>
                                
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Luk</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Gem" : "Opret" }
                    </LoadingButton>       
                </Stack>                   
            </Box>                
        </>
    )
}