// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
} from './../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------

// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export const API_URL_PHONEZOLUTIONS = {
    CUSTOMERS: "/api/phonezolutions/customers/",
    RESELLERS: "/api/phonezolutions/resellers/",
    RECEIVERS: "/api/phonezolutions/receivers/"
}

export const isAdministrator = (user) => {
    return !!(user.usergroups.find(o => o.id === "7fa10ef2-f02a-4976-8638-93b4edcfa27f"))
}

export const isReseller = (user) => {
    return !!(user.usergroups.find(o => o.id === "926cfdc2-e655-412e-b1f5-4dd8ceb50e11"))
}

export const isCustomer = (user) => {
    return !!(user.usergroups.find(o => o.id === "1df86aa9-635a-4a74-8a37-46ffe573a450"))
}