// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,    
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,    
    TextField,            
    IconButton,    
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import {API_URL_PHONEZOLUTIONS} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const {drawerState, setDrawerState, resellerId, callback} = props
    const { accessToken } = SimpleUIAuthState()
    const formStateDefault = {
        isInitializing: true,
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,
        error: false,    
        errorText: "",
        errorName: false,
        errorNameText: "",
        errorEmail: false,
        errorEmailText: "",        
    }
    const formValueDefault = {
        name: "",
        email: "",        
    }        
    const [formState, setFormState] = useState(formStateDefault)   
    const [defaultData, setDefaultData] = useState({})
    const [formData, setFormData] = useState(formValueDefault)    

    useEffect(async () => {
        if (drawerState.open && resellerId != "" && resellerId != null) {                        
            try {
                let resellerGet = await fetch(API_URL_PHONEZOLUTIONS.RESELLERS + resellerId, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!resellerGet.ok)
                    throw new Error((await resellerGet.json()).error.code)

                let reseller = await resellerGet.json()

                setDefaultData(reseller)
                setFormData(reseller)                
            } catch (error) {
                handleError(error)
            }
        }    

        setTimeout(() => {
            setFormState({ ...formState, isInitializing: false })           
        }, 400)
                    
    }, [drawerState.open])

    useEffect(() => {
        let newFormState = {...formState, 
            error: false,    
            errorText: "",
            errorName: false,
            errorNameText: "",
            errorEmail: false,
            errorEmailText: "",
            mainButtonLoading: false,
        }

        let valid = 0

        // Validate: CHANGED
        if (JSON.stringify({...defaultData}) === JSON.stringify({ ...formData}))
            valid++
        
        // Validate: NAME
        if (formData.name.length == 0)
            valid++

        // Validate: EMAIL
        if (!emailValidator.validate(formData.email))
            valid++

        newFormState.mainButtonDisabled = !!(valid)

        setFormState(newFormState)
    }, [formData])

    const handleCreate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let resellerPost = await fetch(API_URL_PHONEZOLUTIONS.RESELLERS, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })

            if (!resellerPost.ok)
                throw new Error((await resellerPost.json()).error.code)

            let newResellerId = (await resellerPost.json()).id

            setDefaultData({...formData, id: newResellerId})
            setFormData({...formData, id: newResellerId})

            if (callback)
                callback()

        } catch (error) {            
            handleError(error)
        }
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, mainButtonLoading: true })

        try {
            let fetchPatch = await fetch(API_URL_PHONEZOLUTIONS.RESELLERS + formData.id, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })  

            if (!fetchPatch.ok) 
                throw new Error((await fetchPatch.json()).error.code)

            setDefaultData({...formData})
            setFormData({...formData})
            
            if (callback)
                callback()

        } catch (error) {
            handleError(error)
        }    
    }

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
       
        // console.log (id +" "+ value)

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}            
            return newState
        })

        return true
    }

    const handleOnClickMainButton = () => {
        if (formData.id)
            handleUpdate()
        else 
            handleCreate()
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })            

        if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der findes allerede en forhandler med denne e-mail adresse." })
        else
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Der opstod en uventet fejl." })
    }

    const handleClose = () => {
        setFormState(formStateDefault)
        setFormData(formValueDefault)
        
        setDrawerState({...drawerState, open: false})
    }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    }     
    
    return (
        <>          
            <Collapse in={formState.error}>
                <Alert variant="filled" severity="error" 
                    action={
                        <IconButton aria-label="close" color="inherit" size="small"
                            onClick={() => {
                                setFormState({ ...formState, error: false })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {formState.errorText}
                </Alert>
            </Collapse> 
            
            <Paper elevation={3} sx={{p: 2}}>
                <Typography variant="h6" sx={{mb: 1}}>
                    {formData.id ? "Redigere forhandler" : "Opret forhandler" }
                </Typography>                            
                <TextField sx={{mb: 1}}
                    required                                     
                    margin="dense"
                    id="name"
                    label="Navn"
                    fullWidth                    
                    variant="outlined"
                    autoComplete="off"
                    value={formData.name}
                    onChange={handleOnChange}                            
                />
                <TextField sx={{mb: 1}}
                    required                             
                    margin="dense"
                    id="email"
                    label="E-mail"
                    fullWidth                    
                    variant="outlined"
                    autoComplete="off"
                    value={formData.email}
                    onChange={handleOnChange}                            
                />
            </Paper>
            
            <Box display="flex" justifyContent="flex-end" sx={{mt: 2}}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleClose}>Luk</Button>
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                        {formData.id ? "Gem" : "Opret" }
                    </LoadingButton>       
                </Stack>                   
            </Box>            
        </>
    )
}