// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Alert,
    Collapse,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,    
    FormControl,
    InputLabel,
    Select,
    MenuItem,    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,    
} from './../../../simpleUI'
// --------------------------------------------------------- OTHER ------
import emailValidator from 'email-validator'
import { v4 as uuidv4 } from 'uuid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import {API_URL_PHONEZOLUTIONS} from '../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const {state, setState, callback} = props 
    const {accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        disabled: false,    
        mainButtonDisabled: true,
        testAPIButtonDisabled: true,        
        testAPIButtonLoading: false,
        apiConnected: false,
        apiConnectedError: false
    }
    const formDataDefault = {}
    const [formState, setFormState] = useState(formStateDefault)
    const [formDefaultData, setFormDefaultData] = useState(formDataDefault)
    const [formData, setFormData] = useState(formDataDefault)    

    useEffect(() => {
        if (state.open) {                
            setFormDefaultData(state.data)
            setFormData(state.data)
        } 
    }, [state.open])

    useEffect(() => {
        let newFormState = {...formState,
            mainButtonLoading: false,
            error: false,    
            errorText: "",            
        }
        
        let formValid = 0
        let formAPIValid = 0
        
        // Validate: CHANGED
        if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData}))
            formValid++

        if (!formData.type)
            formValid++

        if (!formData.title)
            formValid++

        switch(formData.type) {
            case "oneconnect":
                // Validate: EMAIL
                if (!emailValidator.validate(formData.email)) {
                    formValid++
                    formAPIValid++
                }                    

                // Validate: PASSWORD
                if (!formData.password) {
                    formValid++
                    formAPIValid++
                }
                        
                // Validate: PBXID
                if (!formData.pbxId) {
                    formValid++
                    formAPIValid++
                }

                break

            case "sms2go":
                // Validate: GATEWAYID
                if (!formData.gatewayId) {
                    formValid++
                    formAPIValid++
                }
    
                // Validate: JWT
                if (!formData.jwt) {
                    formValid++
                    formAPIValid++
                }

                break

            case "smtp2go":
                // Validate: APIKEY
                if (!formData.apiKey) {
                    formValid++
                    formAPIValid++
                }                

                break
        }
        
        setFormState({...newFormState, 
            mainButtonDisabled: !!(formValid),
            testAPIButtonDisabled: !!(formAPIValid),
            apiConnected: false, 
            apiConnectedError: false
        })
        
    }, [formData])

    const handleSave = () => {
        if (callback != null) {
            switch(formData.type) {
                case "oneconnect":
                    callback({
                        id: formData.id || uuidv4(),
                        type: formData.type,
                        title: formData.title,
                        email: formData.email,
                        password: formData.password,
                        pbxId: formData.pbxId
                    })
                    break

                case "sms2go":
                    callback({
                        id: formData.id || uuidv4(),
                        type: formData.type,
                        title: formData.title,
                        gatewayId: formData.gatewayId,                        
                        jwt: formData.jwt
                    })
                    break

                case "smtp2go":
                    callback({
                        id: formData.id || uuidv4(),
                        type: formData.type,
                        title: formData.title,
                        apiKey: formData.apiKey
                    })
                    break
            }
        }
                   
        handleClose()
    }
    
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   
        
        setState({...state, open: false})

        setFormState(formStateDefault)
    }

    const handleTestAPI = async () => {
        setFormState({...formState, disabled: true, testAPIButtonLoading: true, apiConnected: false, apiConnectedError: false})

        let testResult = false

        try {
            let resellerPost = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS +"testapi", {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({...formData})
            })

            if (!resellerPost.ok)
                throw new Error(await resellerPost.json().error.code)

            testResult = (await resellerPost.json()).result            
        } catch (error) {
            // console.log (error)
        }

        setFormState({...formState, disabled: false, testAPIButtonLoading: false, apiConnected: testResult, apiConnectedError: !testResult})
    }            

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
       
        setFormData(prevState => {
            let newState = {...prevState, [id]: value}            
            return newState
        })

        return true
    }

    const handleOnClickTestAPIButton = () => {
        handleTestAPI()        
    }
    
    return (
        <Dialog fullWidth={true} maxWidth={'md'}
            open={state.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {formData.id ? "Redigere API adgang" : "Tilføj API adgang" }                
            </DialogTitle>
            <DialogContent>                              
                <FormControl sx={{mt: 1, mb: 1}}
                    fullWidth 
                    required             
                    disabled={formData.id ? true : false }     
                >
                    <InputLabel>Type</InputLabel>
                    <Select
                        label="Type"
                        name="type"                    
                        value={formData.type}
                        onChange={handleOnChange}
                    >                                
                        <MenuItem value="oneconnect">One-Connect</MenuItem>
                        <MenuItem value="sms2go">SMS2Go</MenuItem>
                        <MenuItem value="smtp2go">SMTP2Go</MenuItem>
                    </Select>                    
                </FormControl>

                <TextField sx= {{mb: 1}}
                    required                                     
                    margin="dense"
                    id="title"
                    label="Title"
                    fullWidth                    
                    variant="outlined"
                    autoComplete="off"
                    value={formData.title}
                    onChange={handleOnChange}
                />

                {formData.type == "oneconnect" ? (
                    <>
                        <TextField
                            required
                            margin="dense"
                            id="email"
                            label="E-mail"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.email}
                            onChange={handleOnChange}                            
                        />
                        <TextField
                            required
                            margin="dense"
                            id="password"
                            label="Password"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.password}
                            onChange={handleOnChange}                            
                        />
                        <TextField
                            required
                            margin="dense"
                            id="pbxId"
                            label="PBX ID"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.pbxId}
                            onChange={handleOnChange}                            
                        />
                    </>
                ):(
                    <></>
                )}

                {formData.type == "sms2go" ? (
                    <>
                        <TextField
                            required
                            margin="dense"
                            id="gatewayId"
                            label="Gateway ID"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.gatewayId}
                            onChange={handleOnChange}
                            error={formState.errorName}
                            helperText={formState.errorNameText}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="jwt"
                            label="JSON Web Token"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.jwt}
                            onChange={handleOnChange}
                            error={formState.errorName}
                            helperText={formState.errorNameText}
                        />                        
                    </>
                ):(
                    <></>
                )}

                {formData.type == "smtp2go" ? (
                    <>
                        <TextField
                            required
                            margin="dense"
                            id="apiKey"
                            label="API nøgle"
                            fullWidth                    
                            variant="outlined"
                            autoComplete="off"
                            value={formData.apiKey}
                            onChange={handleOnChange}
                            error={formState.errorName}
                            helperText={formState.errorNameText}
                        />                        
                    </>
                ):(
                    <></>
                )}

                {formData.type ? (
                    <>
                        <Collapse in={formState.apiConnected}>
                            <Alert severity="success" sx={{mt: 1}}>Forbindelse lykkedes</Alert>
                        </Collapse>
                        <Collapse in={formState.apiConnectedError}>
                            <Alert severity="warning" sx={{mt: 1}}>Forbindelse fejlede</Alert>
                        </Collapse>
                    </>                    
                ):(
                    <></>
                )}  
            </DialogContent>
            <DialogActions sx={{mr: 2, mb: 2}}>
                {formData.type ? (
                    <LoadingButton variant="outlined" color="primary" loading={formState.testAPIButtonLoading} disabled={formState.testAPIButtonDisabled || formState.disabled} onClick={handleOnClickTestAPIButton}>
                        Test API forbindelse
                    </LoadingButton>
                 ):(
                    <></>
                )}  
                <Button variant="outlined" disabled={formState.disabled} onClick={handleClose}>Luk</Button>
                <Button variant="contained" color="primary" disabled={formState.mainButtonDisabled  || formState.disabled} onClick={handleSave}>
                    {formData.id ? "Anvend" : "Tilføj" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
