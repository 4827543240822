import React from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import {
    SimpleUIApp,

    SimpleUIPageUsers
} from './simpleUI'

import Resellers from "./pages/resellers"
import ResellersIcon from '@mui/icons-material/Badge'

import Customers from "./pages/customers"
import CustomersIcon from '@mui/icons-material/Contacts'

import Receivers from "./pages/receivers"
import ReceiversIcon from '@mui/icons-material/SettingsInputAntenna'

const pages = [
    
    // Customers
    {
        path: "/",
        Main: Customers,
        sidebarnav: {
            icon: CustomersIcon,
            label: "Kunder"
        },
        restrictions: {
            usergroups: [                
                "926cfdc2-e655-412e-b1f5-4dd8ceb50e11"
            ]
        }
    },

    // Resellers
    {
        path: "/",
        Main: Receivers,
        sidebarnav: {
            icon: ReceiversIcon,
            label: "Modtagere"
        },
        restrictions: {
            usergroups: [                
                "1df86aa9-635a-4a74-8a37-46ffe573a450"
            ]
        }
    },

    // Administrators
    {
        path: "/",
        Main: Resellers,
        sidebarnav: {
            icon: ResellersIcon,
            label: "Forhandlere"
        },
        restrictions: {
            usergroups: [
                "7fa10ef2-f02a-4976-8638-93b4edcfa27f",                
            ]
        }
    },
    {
        path: "/customers",
        Main: Customers,
        sidebarnav: {
            icon: CustomersIcon,
            label: "Kunder"
        },
        restrictions: {
            usergroups: [
                "7fa10ef2-f02a-4976-8638-93b4edcfa27f",                
            ]
        }
    },   

    // Resellers / Administrators
    {
        path: "/receivers",
        Main: Receivers,
        sidebarnav: {
            icon: ReceiversIcon,
            label: "Modtagere"
        },
        restrictions: {
            usergroups: [
                "7fa10ef2-f02a-4976-8638-93b4edcfa27f",
                "926cfdc2-e655-412e-b1f5-4dd8ceb50e11",      
            ]
        }
    },
    SimpleUIPageUsers,
]

const settings = {
    useAuthentication: true,
    useEmailAsUsername: true,
    hideAppBar: false,
    hideNavBar: false
}

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp pages={pages} settings={settings}/>        
    </React.StrictMode>,
    document.getElementById('root')
)