// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useCallback, useEffect, useRef, memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {
// } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- QUILL ------
// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
// import dayjs, { Dayjs } from 'dayjs'
// import dayjs from 'dayjs'
// import 'dayjs/locale/da'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Memomize ---
const TextFieldMemo = memo(TextField)
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
// const quillModules = {
//     toolbar: [          
//         ['bold', 'italic', 'underline','strike', 'blockquote'],
//         [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
//     ],
// }
// ----------------------------------------------------------------------
    
export default memo((props) => {  
    const [formData, setFormData] = useState(props.settings)
    const isMounted = useRef(false)    

    useEffect(() => {
        if (isMounted.current) {            
                if (props.onChange)
            props.onChange({...formData})
        } else {            
            isMounted.current = true
        }    
    }, [formData])
    
    const handleOnChange = useCallback((event) => {
        const id = (event.target.name || event.target.id) 
        const value = event.target.value

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}
            return newState
        })
        
        return true
    }, [])

    return (
        <>
            <FormControl sx={{mb: 2}}
                fullWidth 
                required                                            
            >
                <InputLabel>Kald metode</InputLabel>
                <Select                                                
                    name="queryMethod"
                    label="Kald metode"
                    value={formData.queryMethod}
                    onChange={handleOnChange}
                >
                    <MenuItem value="get">GET</MenuItem>
                    <MenuItem value="post">POST</MenuItem>
                </Select>
            </FormControl>
            <TextFieldMemo sx={{mb: 2}}
                name="url"
                label="URL"
                required                                                
                fullWidth
                value={formData.url}
                onChange={handleOnChange}
            />

            <TextFieldMemo sx={{mb: 2}}                                          
                name="headers"
                label="Headers"
                multiline
                fullWidth
                value={formData.headers}
                onChange={handleOnChange}
            /> 

            {/* QUERYMETHOD: POST */}
            {formData.queryMethod == "post" ? (
                <TextFieldMemo sx={{mb: 2}}                                          
                    name="body"
                    label="Body"
                    multiline
                    fullWidth
                    value={formData.body}
                    onChange={handleOnChange}
                />            
            ):(
                <></>
            )}
            <TextFieldMemo sx={{mb: 2}}
                name="output"
                label="Output"                
                fullWidth
                value={formData.output}
                onChange={handleOnChange}
            />
        </>
    )
})