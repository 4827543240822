// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,    
    Stack,
    DialogContentText,
    IconButton,
    Collapse,
    Alert
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
    Refresh as RefreshIcon,    
    Edit as EditIcon,    
    Delete as DeleteIcon,
    Add as AddIcon,        
    PlayCircle as EnabledIcon,
    PauseCircle as DisabledIcon,
    Done as StatusOk,
    Warning as StatusError,
    VpnKey as APICredentials,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {isAdministrator, isReseller, isCustomer, API_URL_PHONEZOLUTIONS} from '../../components/common'
import dayjs from 'dayjs'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIListview,
    SimpleUIDrawer,
    SimpleUIDialogConfirm,
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import FormEdit from './components/edit'
import FormEditAPICrendetials from './components/editAPICredentials'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {user, accessToken} = SimpleUIAuthState()
    const formStateDefault = {
        disabled: false,                                      
        error: false,    
        errorText: "",        
    }
    const [formState, setFormState] = useState(formStateDefault)
    const [toolbarState, setToolbarState] = useState({ create: true, delete: false, edit: false, archive: false, unarchive: false, refresh: true })
    const [dialogStateDeleteReceiver, setDialogStateDeleteReceiver] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    const [drawerCreateState, setDrawerCreateState] = useState(false)
    const [drawerEditState, setDrawerEditState] = useState({ open: false })
    const [drawerEditAPICredentialsState, setDrawerEditAPICredentialsState] = useState({ open: false })
    const [customers, setCustomers] = useState([])
    const [listviewDataReceivers, setListviewDataReceivers] = useState([])
    const [listviewSelectionModelReceivers, setListviewSelectionModelReceivers] = useState([])
    const listviewColumnsReceivers = [
        { field: 'enabled', headerName: '', flex: 0.1, storable: false,
            renderCell: params => {
                if (params.value) 
                    return <EnabledIcon/>
                else
                    return <DisabledIcon/>
            }           
        },         
        { field: 'title', headerName: 'Title', flex: 1.0 },        
        { field: 'lastTriggerTimestamp', headerName: 'Senest udløst', flex: 0.2,
            renderCell: (params) => {                
                if (params.value == "")
                    return "Aldrig"
                else
                    return dayjs.unix(params.value).format('DD/MM/YYYY HH:mm:ss')
            },    
        },
        { field: 'lastTriggerStatus', headerName: 'Status', flex: 0.2,
            renderCell: (params) => {                
                if (params.value == 0)
                    return <StatusOk/>
                else if (params.value > 0)
                    return <StatusError/>
                else
                    return ""
            },
        },
    ]   

    if (isAdministrator(user) || isReseller(user)) {
        listviewColumnsReceivers.splice(2, 0, 
            { field: 'customerId', headerName: 'Kunde', flex: 0.5,
                renderCell: (params) => {                
                    try {return customers.find(o => o.id === params.value).name} catch (error) {return ""}
            },
        })        
    }

    useEffect(async () => {
        handleRefresh ()            
    }, [])

    const handleCreate = () => {
        setDrawerCreateState({...drawerCreateState, open: true})                          
    }

    const handleEdit = () => {
        setDrawerEditState({...drawerEditState, open: true})
    }         

    const handleDelete = () => {
        setDialogStateDeleteReceiver({
            open: true,
            title: "Slet medarbejder",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Er du sikker på du vil slette denne modtager?
                </DialogContentText>),
            button1Text: "Nej",
            button2Text: "Ja",
            onConfirm: () => {
                fetch(API_URL_PHONEZOLUTIONS.RECEIVERS + listviewSelectionModelReceivers, {
                    method: 'DELETE', 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    },           
                })                
                .then(() => {
                    handleRefresh ()                                    
                })
                .catch((error) => {
                    handleError(error)
                })
            },
            onDecline: () => {}
        })
    }
    
    const handleRefresh = async () => {
        try {
            if (isAdministrator(user) || isReseller(user)) {
                let getCustomers = await fetch(API_URL_PHONEZOLUTIONS.CUSTOMERS, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           

                if (!getCustomers.ok)
                    throw new Error((await getCustomers.json()).error.code)

                setCustomers(await getCustomers.json())
            }        

            let getReceivers = await fetch(API_URL_PHONEZOLUTIONS.RECEIVERS, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getReceivers.ok)
                throw new Error((await getReceivers.json()).error.code)

            setListviewDataReceivers(await getReceivers.json())  
        } catch (error) {            
            handleError(error)
        }        
    }    

    const handleError = (error) => {
        setFormState({ ...formState, error: true, errorText: "Der opstod en uventet fejl." })
    }
    
    const handleSelectionReceivers = (selection) => {
        setListviewSelectionModelReceivers(selection)            
        if (selection.length != 0)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleClickAwayReceivers = () => {}

    const handleEditAPICredentials = () => {
        setDrawerEditAPICredentialsState({...drawerEditAPICredentialsState, open: true})
    }

    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateDeleteReceiver} setDialogState={setDialogStateDeleteReceiver}/>
            <SimpleUIDrawer state={drawerCreateState} setState={setDrawerCreateState} callback={handleRefresh}>
                <FormEdit/>
            </SimpleUIDrawer>   
            <SimpleUIDrawer state={drawerEditState} setState={setDrawerEditState} callback={handleRefresh} receiverId={listviewSelectionModelReceivers}>
                <FormEdit/>
            </SimpleUIDrawer>
            <SimpleUIDrawer state={drawerEditAPICredentialsState} setState={setDrawerEditAPICredentialsState} callback={handleRefresh}>
                <FormEditAPICrendetials/>
            </SimpleUIDrawer>   
            <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        width: '100%'
                    }}
                >
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>             
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>    
                    {isCustomer(user) ? (
                        <IconButton color="primary" aria-label="refresh" onClick={handleEditAPICredentials}>
                            <APICredentials />
                        </IconButton>
                    ):(
                        <></>    
                    )}                                
                    
                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                <SimpleUIListview columns={listviewColumnsReceivers} rows={listviewDataReceivers} onSelection={handleSelectionReceivers} onClickAway={handleClickAwayReceivers} rowsPerPageOptions={[100]} selectionModel={listviewSelectionModelReceivers}></SimpleUIListview>
            </Paper>    
        </React.Fragment>    
    )
}
